<template>
    <div class="grid">
        <div class="w-full">
            <Toast />
            <div v-if="!loading" class="formgrid flex flex-wrap column-gap-4 m-3">
                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="tempoParaAgendar">Tempo para agendar (h)</label>
                    <InputText
                        type="number"
                        class="w-full"
                        v-model="form.tempoParaAgendar"
                        required="true"
                        autofocus
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.tempoParaAgendar }"
                    />
                    <small class="p-error" v-if="submitted && !form.tempoParaAgendar">Este campo é obrigatório.</small>
                </div>

                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="tempoRealizacaoAgendamento">Tempo de realização do agendamento (h)</label>
                    <InputText
                        type="number"
                        class="w-full"
                        v-model="form.tempoRealizacaoAgendamento"
                        required="true"
                        autofocus
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.tempoRealizacaoAgendamento }"
                    />
                    <small class="p-error" v-if="submitted && !form.tempoRealizacaoAgendamento">Este campo é obrigatório.</small>
                </div>

                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="tempoParaConfirmar">Tempo para confirmar (h)</label>
                    <InputText
                        type="number"
                        class="w-full"
                        v-model="form.tempoParaConfirmar"
                        required="true"
                        autofocus
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.tempoParaConfirmar }"
                    />
                    <small class="p-error" v-if="submitted && !form.tempoParaConfirmar">Este campo é obrigatório.</small>
                </div>

                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="tempoGeral">Tempo geral Inicio/Fim (h)</label>
                    <InputText
                        type="number"
                        class="w-full"
                        v-model="form.tempoGeral"
                        required="true"
                        autofocus
                        autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.tempoGeral }"
                    />
                    <small class="p-error" v-if="submitted && !form.tempoGeral">Este campo é obrigatório.</small>
                </div>

                <div class="field flex flex-column justify-content-center flex-grow-1 w-full md:max-w-30rem">
                    <label for="tempoPcdRetornoAoTrabalhoConsultaTerceiros">Tempo para PCD, retorno ao trabalho, consultas e terceiros (min)</label>
                    <InputNumber
                        id="tempoPcdRetornoAoTrabalhoConsultaTerceiros"
                        class="w-full"
                        v-model="form.tempoPcdRetornoAoTrabalhoConsultaTerceiros"
                        mode="decimal"
                        showButtons
                        buttonLayout="horizontal"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :step="1"
                    />
                </div>

                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="limiteDatas">Limite Maximo Range de Datas</label>
                    <InputNumber
                        id="limiteDatas"
                        class="w-full"
                        v-model="form.limiteDatas"
                        mode="decimal"
                        showButtons
                        buttonLayout="horizontal"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :step="1"
                        :class="{ 'p-invalid': submitted && !form.limiteDatas }"
                    />
                    <small class="p-error" v-if="submitted && !form.limiteDatas">Este campo é obrigatório.</small>
                </div>

                <div class="field flex flex-column justify-content-center w-full md:max-w-30rem">
                    <label for="limiteDatas">Considerar procedimentos em dia em:</label>
                    <InputNumber
                        id="limiteDatas"
                        class="w-full"
                        v-model="form.considerarProcedimentosEmDiaEm"
                        mode="decimal"
                        showButtons
                        buttonLayout="horizontal"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :step="1"
                    />
                </div>

                <div class="field flex flex-column justify-content-center flex-grow-1 w-full md:max-w-30rem">
                    <label for="limiteDiasBuscaAsoSoc">Limite dias para busca do ASO no SOC:</label>
                    <InputNumber
                        id="limiteDiasBuscaAsoSoc"
                        class="w-full"
                        v-model="form.limiteDiasBuscaAsoSoc"
                        mode="decimal"
                        showButtons
                        buttonLayout="horizontal"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :step="1"
                    />
                </div>

                <div class="field flex justify-content-start align-items-center w-full">
                    <Button
                        :loading="loadingSalvar"
                        v-tooltip.bottom="'Salvar'"
                        icon="pi pi-check"
                        class="p-button-primary button w-full md:max-w-10rem"
                        label="Salvar"
                        @click="save"
                    />
                </div>
            </div>

            <AppLoadingWrapper v-if="loading" />
        </div>
    </div>
</template>

<script>
import AreaMedicaService from '@/services/AreaMedicaService';
import BaseService from '@/services/BaseService';
import { showError, showSuccess } from '@/utils/Toast';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            actionItems: [
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeleteRecord(this.actionRecord)
                }
            ],
            form: {},
            submitted: false,
            records: [],
            clientes: [],
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 2,
            recordDialog: false,
            deleteRecordDialog: false,
            filters: null,
            active: 0,
            loading: false,
            loadingSalvar: false,
            loadingExcluir: false
        };
    },
    async mounted() {
        this.$service = new AreaMedicaService('/configuracoes_sla');
        this.$customerService = new BaseService('/customers');
        this.load();
        this.loadCustomers();
        if (this.$route.params.id) {
            const { data } = await this.$service.findById(this.$route.params.id);
            this.form = data;
        }
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        page() {
            this.load();
        },
        records: {
            handler(records) {
                const registro = records[0];
                if (Array.isArray(records)) {
                    this.form = { ...registro };
                }
            },
            deep: true
        }
    },
    methods: {
        async save() {
            this.loadingSalvar = true;

            this.submitted = true;
            try {
                await this.$service.save(this.form);
                showSuccess(this.$toast, 'Parâmetros salvos com sucesso!');
                this.submitted = false;
                this.loadingSalvar = false;
                this.load();
            } catch (err) {
                this.loadingSalvar = false;
                showError(this.$toast, err, 'Problemas ao salvar os parâmetros');
            }
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll({ filter: true });
                this.records = data;
                this.total = data.total;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async loadCustomers() {
            const { data } = await this.$customerService.findAll({});
            this.clientes = data.items;
        }
    }
};
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.button {
    margin-top: 20px;
    height: 34px;
}

.empty-data {
    top: 30px !important;
    bottom: 10cm;
}

.empty-data-mobile {
    display: none;
}

@media only screen and (max-width: 640px) {
    #dataTable {
        display: none;
    }

    .empty-data-mobile {
        display: block;
    }
}
</style>
