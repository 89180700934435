<template>
    <div>
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <span class="text-primary pi pi-info-circle" v-tooltip="'Controle de Falta Consulta'"></span>
                Controle de Falta Consulta
            </div>
        </div>
        <div class="flex flex-row text-900 font-normal text-lg ml-3 mb-5">
            Selecione o período de tempo(Máximo 3 meses) e o Cliente(opcional), para emitir o
            <div class="font-medium ml-1">Relatório de Falta Consulta.</div>
        </div>
        <div class="flex flex-row">
            <div class="flex col-4 flex-column m-1">
                <label class="mb-2"> <span :style="{ color: 'red' }">*</span> Período</label>
                <Calendar
                    placeholder="Selecione o período..."
                    id="range"
                    class="w-auto"
                    v-model="filtrosExtras.datas"
                    selectionMode="range"
                    :showIcon="true"
                />
            </div>
            <div class="flex col-4 flex-column m-1">
                <label class="mb-2">Cliente(Opcional) </label>
                <MultiSelectCliente class="w-auto" :key="tenant" id="clientes" v-model="filtrosExtras.clientes" />
            </div>
            <div class="align-self-end flex-column flex col-4 m-1">
                <Button
                    icon="pi pi-file-excel"
                    :loading="loadingExcel"
                    class="p-button-success h-3rem w-auto"
                    v-tooltip.bottom="'Gerar Planilha'"
                    @click="gerarPlanilha()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import { getTenant, getToken } from '@/common/storage';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches } from '../../../services/auth';
import { getFilterCustomerIds } from '../../../services/filters';
import axios from 'axios';
import moment from 'moment-timezone';
import { getCurrentCompany } from '@/services/store';

export default {
    components: {
        MultiSelectCliente
    },
    data() {
        return {
            filtrosExtras: {},
            loadingExcel: false
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany?.() || 1;
        }
    },
    methods: {
        async gerarPlanilha() {
            if (!this.filtrosExtras?.datas) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Selecione um período!',
                    detail: 'Selecione um período de no máximo 3 meses para emitir o Relatório de Falta Consulta',
                    life: 3000
                });
                return;
            }

            const rangeMaiorQueTresMeses = await this.verificarSeRangeDatasMaiorQueTresMeses();

            if (rangeMaiorQueTresMeses) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Selecione um período!',
                    detail: 'Selecione um período de no máximo 3 meses para emitir o Relatório de Falta Consulta',
                    life: 3000
                });
                return;
            }

            this.loadingExcel = true;
            const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
            const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
            const filteredCustomerIds = getFilterCustomerIds();
            const filtrosExtras = {
                clientes: this.filtrosExtras.clientes?.map((clientes) => {
                    return clientes.id;
                }),
                datas: this.filtrosExtras.datas
            };
            try {
                const res = await axios({
                    url: process.env.VUE_APP_API_BASE_URL + `/controle-de-falta-consulta/gerar-csv/0`,
                    params: {
                        filter: this.filter,
                        sort: this.sort,
                        filtrosExtras
                    },
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        'x-api-key': getTenant(),
                        'filter-customer': JSON.stringify(filteredCustomerIds),
                        customers: JSON.stringify(idsAuthorizedCustomers),
                        'customer-branches': JSON.stringify(idsAuthorizedBranches)
                    }
                });
                const file = window.URL.createObjectURL(new Blob([res.data]));
                const docUrl = document.createElement('a');
                const dataComeco = moment.tz(this.filtrosExtras?.datas[0], 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
                const dataFinal = moment.tz(this.filtrosExtras?.datas[1], 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
                docUrl.href = file;
                docUrl.setAttribute(
                    'download',
                    `relatório_falta_consulta_${this.$filters.formatDateOnly(dataComeco)}_até_${this.$filters.formatDateOnly(dataFinal)}.xlsx`
                );
                document.body.appendChild(docUrl);
                docUrl.click();
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops, algo aconteceu!',
                    detail: `Nenhum agendamento foi encontrado! ${error.response.data.message ?? ' '}`,
                    life: 3000
                });
            }
            this.loadingExcel = false;
        },
        verificarSeRangeDatasMaiorQueTresMeses() {
            const quantidadeDiasEntreAsDatas = this.buscaDiffEmDia();

            return quantidadeDiasEntreAsDatas > 90;
        },
        buscaDiffEmDia() {
            const dataMenor = this.filtrosExtras?.datas[0];
            const dataMaior = this.filtrosExtras?.datas[1];
            const diffEmMeses = dataMaior - dataMenor;
            const diffEmDias = diffEmMeses / (1000 * 60 * 60 * 24);
            return diffEmDias;
        }
    }
};
</script>
<style></style>
